export const all = [
  {
    key: 'country.poland',
    value: 'Polska',
  },
  {
    key: 'country.germany',
    value: 'Niemcy',
  },
  {
    key: 'country.austria',
    value: 'Austria',
  },
  {
    key: 'country.belgium',
    value: 'Belgia',
  },
  {
    key: 'country.romania',
    value: 'Rumunia',
  },
  {
    key: 'country.hungary',
    value: 'Węgry',
  },
  {
    key: 'country.czech',
    value: 'Czechy',
  },
  {
    key: 'country.slovakia',
    value: 'Słowacja',
  },
  {
    key: 'country.slovenia',
    value: 'Słowenia',
  },
  {
    key: 'country.croatia',
    value: 'Chorwacja',
  },
  {
    key: 'country.ukraine',
    value: 'Ukraina',
  },
  {
    key: 'country.uk',
    value: 'Wielka Brytania',
  },
  {
    key: 'country.bosnia',
    value: 'Bośnia i Hercegowina',
  },
  {
    key: 'country.serbia',
    value: 'Serbia',
  },
  {
    key: 'country.montenegro',
    value: 'Czarnogóra',
  },
  {
    key: 'country.albania',
    value: 'Albania',
  },
  {
    key: 'country.macedonia',
    value: 'Macedonia',
  },
  {
    key: 'country.bulgaria',
    value: 'Bułgaria',
  },
  {
    key: 'country.greece',
    value: 'Grecja',
  },
  {
    key: 'country.turkey',
    value: 'Turcja',
  },
  {
    key: 'country.belarus',
    value: 'Białoruś',
  },
  {
    key: 'country.latvia',
    value: 'Łotwa',
  },
  {
    key: 'country.lithuania',
    value: 'Litwa',
  },
  {
    key: 'country.estonia',
    value: 'Estonia',
  },
  {
    key: 'country.finland',
    value: 'Finlandia',
  },
  {
    key: 'country.sweden',
    value: 'Szwecja',
  },
  {
    key: 'country.russia',
    value: 'Rosja',
  },
  {
    key: 'country.moldova',
    value: 'Mołdawia',
  },
];

export const getCountryByKey = (key) => all.find((country) => country.key === key);
